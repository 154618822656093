<template>
  <div class="list-group-wrapper" v-if="props.isSearch">
    <ul class="list-group" id="infinite-list">
      <div v-if="props.loadingState == 'loading'" class="text-center">
        <div class="info-loading">
          <i
            style="font-size: 17px; opacity: 0.8; color: #0f6a33"
            class="bx bx-loader-alt loading-icon"
          ></i>
          <span>{{ $t("t-loading-get-data") }}</span>
        </div>
      </div>
      <div
        v-if="
          props.loadingState == '' &&
          props.dataSearch.length == 0 &&
          props.isSearch
        "
        class="info-loading"
      >
        <i
          class="ri-folder-forbid-line"
          style="font-size: 17px; opacity: 0.8; color: #e0ba00"
        ></i>
        <span>{{ $t("t-no-data") }}</span>
      </div>
      <li
        v-else
        class="list-group-item"
        v-for="(item, index) in props.dataSearch"
        v-text="item.label"
        :key="index"
        @click="emitKeySearch(item)"
      ></li>
    </ul>
  </div>
</template>

<script setup>
import { defineEmits, defineProps } from "vue";
const emit = defineEmits([""]);
const props = defineProps({
  dataSearch: Array,
  isSearch: Boolean,
  loadingState: String,
});
const emitKeySearch = (keySearch) => {
  emit("keySearch", keySearch);
};
</script>
<style scoped>
.list-group-wrapper {
  position: absolute;
  background-color: #fff;
  z-index: 1000;
  width: 288px;
}
.list-group {
  overflow: auto;
  max-height: 200px;
  border-radius: 5px;
  min-height: 35px;
  line-height: 35px;
  padding: 5px 0;
  box-shadow: 0 0.46875rem 2.1875rem #04091408, 0 0.9375rem 1.40625rem #04091408,
    0 0.25rem 0.53125rem #0409140d, 0 0.125rem 0.1875rem #04091408;
}
.list-group-item {
  padding: 0 10px;
  border-left: none;
  border-right: none;
  border-top: none;
  cursor: pointer;
  transition: 0.2s;
}
.list-group-item:nth-child(2n) {
  background-color: #fafafa;
}
.list-group-item:hover {
  background-color: #f5f7fa;
}
.info-loading {
  display: flex;
  align-items: center;
  justify-content: center;
}
.info-loading i {
  margin-right: 10px;
}
</style>
