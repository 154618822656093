import { defineStore } from "pinia";
import mushroom from "cem-probe-api";
import { abortAPI } from "@/stores/abortAPI";

const abortController = new AbortController();
const storeAbortAPI = abortAPI();

export const generalKhachHang = defineStore({
  id: "generalKH",
  state: () => ({
    thresholdName: [],
    listIdService: [],
    listNameService: [],
    tableData: {
      columns: [],
      data: [],
    },
    tableDataUser: {
      columns: [],
      data: [],
    },
    // tableDataOther: {
    //     columns: [],
    //     data: []
    // },
    tableSessionData: {
      columns: [],
      data: [],
    },
    tableSessionUser: {
      columns: [],
      data: [],
    },
    // listCustomers: [],
    valueThreshold: [],
    listProbe: [],
    fileSumData: "",
    fileSessionData: "",
  }),
  actions: {
    async getThresholdName() {
      try {
        const response = await mushroom.quality_level.listAsync();
        if (response.result) {
          this.thresholdName = response.result.map((item) => {
            return {
              level: item.level,
              name: item.threshold_name,
            };
          });
        }
      } catch (e) {
        console.error("Có lỗi: %o", e);
      }
    },

    async getSummary() {
      try {
        const response = await mushroom.customer_summary.listAsync();
        if (response.result) {
          this.listIdService = response.result.map((item) => item.service_id);

          this.listIdService = this.listIdService.filter(
            (item, index) => this.listIdService.indexOf(item) === index
          );
          await this.getService();
        }
      } catch (e) {
        console.error("Có lỗi: %o", e);
      }
    },

    async getService() {
      const ids = this.listIdService.filter((id) => !!id);
      if (ids.length) {
        try {
          const response = await mushroom.service.listAsync({
            filters: `id:in:${ids}`,
          });
          if (response.result) {
            this.listNameService = response.result.map((item) => {
              return {
                id: item.id,
                name: item.name,
              };
            });
          }
        } catch (e) {
          console.error("Có lỗi: %o", e);
        }
      }
    },

    async getData(dataFilter) {
      try {
        const body = JSON.parse(JSON.stringify(dataFilter));
        body.service_ids = body.service_ids.filter((id) => !!id);
        
        storeAbortAPI.setAbort(abortController);
        const response = await mushroom.probe_data.getSumDataCustomerAsync({
          body: body,
          settings: {
            abortController
          }
        });
        if (response.result) {
          // list user
          // const colIdentity = [];
          this.tableDataUser.columns = response.result.columns.filter(
            (x) => x.is_identity
          );
          let indexUser;
          response.result.columns.forEach((item, index) => {
            if (item.is_identity) {
              indexUser = index;
            }
          });
          this.tableDataUser.data = response.result.data.map(
            (x) => x[indexUser]
          );

          // list service
          const colGroups = [];
          // response.result.columns.forEach((column, index) => {
          //     column.col_position = index;
          // })
          response.result.columns.forEach((column) => {
            if (column.service_id) {
              const colGroup = colGroups.find(
                (grp) => grp.service_id == column.service_id
              );
              if (colGroup) {
                // nếu đã có service trong colGroups rồi thì push column vào mảng columns
                colGroup.columns.push(column);
              } else {
                colGroups.push({
                  service_id: column.service_id,
                  columns: [column],
                });
              }
            } else {
              colGroups.push(column);
            }
          });

          colGroups.forEach((colGroup) => {
            colGroup.name_service = this.listNameService.filter(
              (item) => item.id === colGroup.service_id
            )?.[0]?.name;
          });
          response.result.colGroups = colGroups;
          this.tableData = response.result;
        } else {
          this.tableData.data = [];
        }
      } catch (error) {
        this.tableData.data = [];
        console.error("Có lỗi: %o", error);
      }
    },

    // lấy base64 bảng tổng hợp
    async getFileSumData(dataFilter) {
      try {
        const body = JSON.parse(JSON.stringify(dataFilter));
        body.service_ids = body.service_ids.filter((id) => !!id);
        storeAbortAPI.setAbort(abortController);
        const response = await mushroom.probe_data.getSumDataCustomerAsync({
          body: body,
          settings: {
            abortController
          }
        });
        if (response.result) {
          this.fileSumData = response.result;
        } else {
          this.fileSumData = "";
        }
      } catch (error) {
        this.fileSumData = "";
        console.error("Có lỗi: %o", error);
      }
    },

    async getSessionData(dataFilter) {
      try {
        storeAbortAPI.setAbort(abortController);
        const response = await mushroom.probe_data.getSessionDataCustomerAsync({
          body: dataFilter,
          settings: {
            abortController
          }
        });
        if (response.result) {
          // list user
          this.tableSessionUser.columns = response.result.columns.filter(
            (x) => x.is_identity
          );
          let indexUser;
          response.result.columns.forEach((item, index) => {
            if (item.is_identity) {
              indexUser = index;
            }
          });
          this.tableSessionUser.data = response.result.data.map(
            (x) => x[indexUser]
          );

          // list service
          const colGroups = [];
          response.result.columns.forEach((column) => {
            if (column.service_id) {
              const colGroup = colGroups.find(
                (grp) => grp.service_id == column.service_id
              );
              if (colGroup) {
                // nếu đã có service trong colGroups rồi thì push column vào mảng columns
                colGroup.columns.push(column);
              } else {
                colGroups.push({
                  service_id: column.service_id,
                  columns: [column],
                });
              }
            } else {
              colGroups.push(column);
            }
          });

          colGroups.forEach((colGroup) => {
            colGroup.name_service = this.listNameService.filter(
              (item) => item.id === colGroup.service_id
            )?.[0]?.name;
          });
          response.result.colGroups = colGroups;
          response.result.colFixed = indexUser;
          this.tableSessionData = response.result;
        } else {
          this.tableSessionData.data = [];
        }
      } catch (error) {
        this.tableSessionData.data = [];
        console.error("Có lỗi: %o", error);
      }
    },

    // lấy base64 bảng session
    async getFileSessionData(dataFilter) {
      try {
        storeAbortAPI.setAbort(abortController);
        const response = await mushroom.probe_data.getSessionDataCustomerAsync({
          body: dataFilter,
          settings: {
            abortController
          }
        });
        if (response.result) {
          this.fileSessionData = response.result;
        } else {
          this.fileSessionData = "";
        }
      } catch (error) {
        this.fileSessionData = "";
        console.error("Có lỗi: %o", error);
      }
    },

    async getProbes(probe_id) {
      try {
        storeAbortAPI.setAbort(abortController);
        const response = await mushroom.probe_data.getCustomersAsync({
          body: {
            probe_id: probe_id,
          },
          settings: {
            abortController
          }
        });
        if (response.result?.length > 0) {
          response.result.forEach((item) => {
            this.listProbe.push({
              key: item.key,
              label: item.label,
            });
          });
        } else {
          this.listProbe = [];
        }
      } catch (error) {
        console.error("Có lỗi: %o", error);
      }
    },

    // setCustomers() {
    //     let countUser = 0;
    //     this.listCustomers = [];
    //     this.tableData.columns.forEach((item, index) => {
    //         if (item.is_identity) {
    //             countUser = index;
    //         }
    //     });

    //     this.tableData.data.forEach((item) => {
    //         this.listCustomers.push(item[countUser]);
    //     });
    // },

    changeThreshold(data) {
      this.valueThreshold = data;
    },
  },
  getters: {
    getCheckService() {
      return this.listNameService.map((x) => x.id);
    },
    getNotValueSession() {
      return (this.tableSessionData.data = []);
    },
    showListNameService() {
      return this.listNameService;
    },
  },
});
